/* style.scss */

/*https://kapeli.com/cheat_sheets/CSS_Device_Media_Queries.docset/Contents/Resources/Documents/index*/
/*https://mediag.com/blog/popular-screen-resolutions-designing-for-all/*/

/* shared color variables -- 
here for foundation too 
(foundation uses global.scss for colors)*/
$seasalt-blue: #004FB5 !default;
$seasalt-neonblue: #01B5F0 !default;
$seasalt-lightblue: #E8F3F5 !default;

$reveal-overlay-background: rgba(0,0,0, .5) !default;


@import "vendor/foundation/assets/foundation";
/* @import "../css/vendor/css/foundation.min"; */
@import "webfonts";
@import "vendor/photoswipe/main";
@import "vendor/photoswipe/default-skin/default-skin";



/* INDEX */
/* 
-variables
-shared
	- nav
-pages and page overrides
-site overrides 
- mobile
*/


/* Variables */

/* 
font-family: 'IM Fell Great Primer', serif;
font-family: 'IM Fell Great Primer SC', serif; this is all caps
font-family: 'Cormorant', serif;
font-family: 'Cormorant SC', serif;  this is all caps*/

/* shared */
.bold{
	font-weight: bold;
}

.redWarning{
	color: red;
	text-decoration: underline;
	cursor: pointer;
}


body, body p{
	font-family: 'Cormorant', serif;
	font-size: 1.5rem;
	font-weight: 400;
}



a, a:visited{
	color: $seasalt-blue;
}

a:hover{
	text-decoration: underline;
	color: rgba($seasalt-blue, .75);
}

a.telfont{
	/*font-family: 'Cormorant', serif;*/
	font-family: "Times";
	font-size: 1.25rem;
	color: $seasalt-blue;
	}

h1{
	text-align:center;
}

h1, h2, h3{
		font-family: 'IM Fell Great Primer SC', serif;
		/*font-family: 'IM_Fell_Great_Primer_SC', serif;*/
		color: $seasalt-blue;
		/* font-size:3rem; */
		/* text-transform: uppercase; */
	}




#siteTitle{

		font-family: 'IM Fell Great Primer SC', serif;
		/*font-family: 'IM_Fell_Great_Primer_SC', serif;*/
		color: $seasalt-blue;

		/*iphone SE/6 fix*/
		@media only screen and (max-width: 350px) {
			font-size: 1.25rem;
			/*padding-top:5px;*/
		}
}

#stickyFooter{
	font-family: 'IM Fell Great Primer SC', serif;
	font-size: 1rem;
	background-color: rgba(0, 0, 0, .25);
	ul{ 
		list-style: none;
		margin-left: 0px;
		li{
		color: #fff;
		text-align: center;
		}
	}
}


#infoFooter{
	font-family: 'Cormorant SC', serif;
	font-size: 1.25rem;
	background-color: #fff;
	border-top:5px solid $seasalt-blue;
	padding-top: 20px;
	padding-bottom: 20px;	

	h3{
		display: block;
		position: relative;
		text-align: center;
		width:100%;
		padding-bottom: 20px;
	}

	ul{ 
		list-style: none;
		text-align: center;
		/*margin-left: 0px;*/
		li a img{
			width:150px;
		}



		li, li p{
			font-family: 'Cormorant SC', serif;
			color: $seasalt-blue;;
			text-align: center;
			/* font-size:1.5rem; */
			/*@media screen and #{breakpoint(small down)} {
 				font-size: 1.125rem; 
			}*/
			&.heading{
				font-family: 'IM Fell Great Primer SC', serif;
				text-decoration: underline;
				font-size: 1.5rem;
			}
			i{
				/*font-size:2rem;*/
			}
			.fa-1x{
				font-size: 1rem;
			}
		}
	}
}

#globalFooter{
	font-family: 'Cormorant SC', serif;
	font-size: 1.25rem;
	background-color: $seasalt-lightblue;
	padding-top: 50px;
	padding-bottom: 20px;
	ul{ 
		list-style: none;
		margin-left: 0px;
		
		li, li p{
			font-family: 'Cormorant SC', serif;
			color: $seasalt-blue;;
			text-align: center;
			/* font-size:1.5rem; */
			@media screen and #{breakpoint(small down)} {
 				font-size: 1.125rem; 
			}
			&.heading{
				font-family: 'IM Fell Great Primer SC', serif;
				text-decoration: underline;
				font-size: 1.5rem;
			}
			i{
				/*font-size:2rem;*/
			}
			.fa-1x{
				font-size: 1rem;
			}
		}
	}
}

#sitemapFooter{
	margin-top: 30px;
}

#devCredit{
	font-size: 1rem;
}

/* nav */
#navLogo{
	position: absolute;
	width: 100px;
	/* background: url(../img/shared/seasalt-charters-logo.png); */
	z-index: 1;
	/* background-size: 50px;
	background-repeat: no-repeat; */
	top:10px;
}

#navBg{
	background-color: #fff;
	border-bottom: 1px solid $seasalt-blue;
}

.top-bar{
	font-family: 'IM Fell Great Primer SC', serif;
	text-transform: uppercase;
	font-size: 1rem;
	ul li a:hover{
		color: rgba($seasalt-blue, .5);
	}
	
	ul li.active li a:hover{
		color: rgba($seasalt-blue, .5);
	}

	ul li.active li.active a:hover{
		color: #fff;
	}

	ul li.active li a{
		color: $seasalt-blue;
	}


	ul li.active > a:first-child{
		color: #fff;
	}

	ul li.active > a:first-child{
		background-color: $seasalt-blue;
	}

	.fa-1x{
		font-size: 1rem;
	}

	.is-dropdown-submenu{
		li.active{}	
	}

	.is-dropdown-submenu.contact-drop{
		min-width: 230px;
	}

	.is-dropdown-submenu.about-drop{
		min-width: 160px;
	}

	.is-dropdown-submenu.charters-drop{
		min-width: 420px;
	}

	@include breakpoint(medium) {
		.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent.active > a::after {
			border-top-color: #fff;	
		}


		.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent.active.is-active > a::after {
			border-top-color: #1779ba;	
		}

		
	}

	.menu .active > a {
		width:100%;
	}

		
}


/* per zurb preventing FOUC */
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar-right {
      display: none;
    }
  }
}

/*nav mobile*/
@include breakpoint(medium down) {
	#navLogo{	
		left:20px;
		width: 50px;
		padding-top:5px;
	}

	#siteTitle{
		top:-20px;
	}

	.menu-icon {   
	    left: -30px;
	    width: 30px;
	    z-index: 100;
	    top:20px;
	}
	.menu-icon::after{
		height: 3px;
	}
	.top-bar{
		width: 100%;
	}
	.is-drilldown, .menu.drilldown{
		width: 100%;
		li{
			width: 100%;
		}
	}

	.drilldown .is-drilldown-submenu-parent > a::after{
		right: 0rem;
	}

	.active.is-drilldown-submenu-parent > a::after{
		right: 1rem;
		border-left-color: #fff !important;
	}
	


	/*.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a.active::after {
		border-top-color: #fff;
		top: 18px;
	}*/


}

/*sticky fix for mobile*/
.sticky.asaStuck {
    position: fixed;
    z-index: 5;
    width: 100%;
    top:0px !important;
}

/* Page overrides */

main{
	position: relative;
	/*top: -16px;*/
	/*top:-105px;*/
	z-index: -1;
	/*padding-top: 50px;*/

}

.paddingTopMain{
	margin-top: 100px;
}




/* Home */




// new home copy stytles 

#homeCopy{
	text-align: center;
	/*@media only screen and (min-width: 321px) and (max-width: 800px) and (orientation: landscape) {
		
		margin-top:-50px;
		}
	}*/
}

#homeTitle{
	/*font-family: 'Cormorant SC', serif;*/
	font-family: 'Cormorant', serif;
	font-size: 2rem;
	color: #fff;

	/*small iphone SE 6*/
	@media only screen and (max-width: 320px) {
		font-size: .9rem;
	}
	
	/*up to all iphone plus */
	@media only screen and (min-width: 321px) and (max-width: 414px) {
		font-size: 1rem;

	}
	/* up to ipad */
	@media only screen and (min-width: 415px) and (max-width: 768px) {
		font-size: 1.25rem;
	}

	/* up to 12.9 ipad pro landscape */
	@media only screen and (min-width: 769px) and (max-width: 1366px) {
		font-size: 2rem;
	}
	
	/*larger than ipad pro landscape*/
	@media only screen and (min-width: 1367px){
		font-size: 2rem;
	}

	@media only screen and (orientation: landscape) {

	}
}

#homeSub{
	font-family: 'IM Fell Great Primer SC', serif;
	color: #fff;
	/*small iphone SE 6*/
	@media only screen and (max-width: 320px) {
		font-size: 2rem;
		margin-top:50px;
	}	
	/*up to all iphone plus */
	@media only screen and (min-width: 321px) and (max-width: 414px) {
		font-size: 2rem;
		margin-top:75px;
	}

	/* up to ipad */
	@media only screen and (min-width: 415px) and (max-width: 768px) {
		font-size: 2rem;
		margin-top: 80px;
	}
	/* up to 12.9 ipad pro landscape */
	@media only screen and (min-width: 769px) and (max-width: 1366px) {
		font-size: 3rem;
		margin-top: 50px;
	}	
	/*laptop*/
	@media only screen and (min-width: 1367px){
		font-size:4rem;
		line-height: 5rem;
		margin-top: 80px;
		/*margin-bottom: 80px;*/
	}
}

#andMore{
	margin: 0 auto;
	text-align: center;
	

	a{
		font-family: 'Cormorant SC', serif;
		font-size: 2rem;
		color: #fff;	

	}
}

#homeRates, #homeBook{
	color: #fff;
	background-color: transparent;
	border: 1px solid;
	border-radius: 10px;
	font-family: 'IM Fell Great Primer SC', serif;
	text-align: center;
	font-size: 1.75rem;
	padding:10px;
	padding-left:20px;
	padding-right:20px;

	&:hover{
		background-color: #fff;
		color: $seasalt-blue;
	}
	/*small iphone SE 6*/
	@media only screen and (max-width: 320px) {
		font-size: 1rem;
	}

	/*up to all iphone plus */
	@media only screen and (min-width: 321px) and (max-width: 414px) {
		
	}
	/* up to ipad */
	@media only screen and (min-width: 415px) and (max-width: 768px) {
		
	}
	/* up to 12.9 ipad pro landscape */
	@media only screen and (min-width: 769px) and (max-width: 1366px) {
		
	}
	/*larger than ipad pro landscape*/
	@media only screen and (min-width: 1367px){

	}
}

#homeRates{
	margin-right: 20px;
}

#homeCTA{
	margin: 0 auto;
	text-align: center;
	width: 100%;
	/*small iphone SE 6*/
	@media only screen and (max-width: 320px) {
		margin-top: 60px;
	}
	/*up to all iphone plus */
	@media only screen and (min-width: 321px) and (max-width: 414px) {
		margin-top: 80px;
	}
	/* up to ipad */
	@media only screen and (min-width: 415px) and (max-width: 768px) {
		margin-top: 80px;	
	}
	/* up to 12.9 ipad pro landscape */
	@media only screen and (min-width: 769px) and (max-width: 1366px) {
		
		margin-top: 50px;	
	
	}
	/*larger than ipad pro landscape*/
	@media only screen and (min-width: 1367px){
		margin-top: 80px;
	}
}



#homeScreen{
	
	top:70px;
	margin-bottom: 100px;


	/*@include breakpoint(medium up) {*/
	background: url(../img/home/seasalt-charters-home.jpg);
	background-size: cover;
	background-position-y: 0px;
	background-position-x: center; 		
	/*object-fit: cover;*/
	/*mobile full screen ??*/
	height:100vh;
	width:100%;
	position: relative;
	padding-top: 10%;
	background-repeat: no-repeat;

	/* up to ipad */
	@media only screen and (max-width: 768px) {
		background-image: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0)),
			url(../img/home/seasalt-charters-home-whale-m.jpg);
		
		/*background-blend-mode: screen;*/
		background-size: cover;
		background-position-y: -70px;
		background-position-x: center, right; 		
		
		margin-bottom: 50px;
	}


}

#homeButtons{
	/*padding-top: 50px;
	padding-bottom: 30px;
	@include breakpoint(small only) {
		
	}*/
}


/*page banner*/
div#pageBanner{
	
	/*_______now inline______*/

	/*background: linear-gradient(to bottom,black 0%,white 100%), url(../img/shared/CopyofAmyPoturnicki.jpg);*/
	/*background-blend-mode: screen;
	background-size: cover;
	background-repeat: no-repeat;
	width:100%;
	background-position-y: center;
	background-position-x: center;
	position: relative;
	top:72px;*/
	height:75vh;
	/*

	*/
	h1{
		/*padding-top:450px;*/
		/*color:#fff;*/
		position: absolute;
	    bottom: 0px;
	    width: 100%;
	}
}


/*rates*/
ul#rates-tabs{ 
	h3 {
		text-align: center;
		font-size: 1rem !important;

	}


}

#rates-tables{
	font-size: 1rem;
	table {
		text-align: center
	}

	h2{
		text-align: center;
	}
	h4 {font-weight: bold;}
	p{
		line-height: 1rem !important;
		font-size: 1.125rem;
	}
	.tabs-panel{
		padding:1rem 0rem;
	}
}

.tabs-title {
	float:none !important;
	display:inline-block;
	vertical-align: middle;

		
		width:16.6%;
	

}

.tabs {
	text-align:center;
}

.tabs-title > a{
	padding: .5rem;

}